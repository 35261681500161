<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-if="itemId > 0" v-slot:activator="{ on }">
      <a
        v-on="on"
        @click.prevent="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
        >{{ $t("curration.dialog.model.action.edit_record") }}</a
      >
    </template>
    <template v-else v-slot:activator="{ on }">
      <a
        v-on="on"
        @click.prevent="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
        >{{ $t("curration.dialog.model.action.add_record") }}</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">{{ $t("curration.dialog.model.title") }}</span>
          <small>
            {{ $t("curration.dialog.model.subtitle") }}
          </small>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="curation.name"
                  required
                  :label="this.$t('curration.dialog.model.fields.name.label')"
                  ref="f_name"
                  :rules="[
                    v =>
                      !!v || this.$t('curration.dialog.model.fields.name.rule')
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-for="query in curation.queries" :key="query.id">
              <v-col cols="8">
                <v-text-field
                  v-model="query.query"
                  required
                  :label="$t('curration.dialog.model.fields.query.label')"
                  ref="f_queries"
                  :rules="[
                    v =>
                      !!v || this.$t('curration.dialog.model.fields.query.rule')
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn
                  color="error dark large"
                  @click="removeValue(query.id)"
                  text
                  >{{ $t("common.remove") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-btn @click="addValue" text>{{
                  $t("common.add_value")
                }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="darken-1" text @click="dialog = false">{{
            $t("common.close")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >{{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import {
  POST_CURATION,
  PULL_CURATION,
  PULL_CURATIONS,
  PUT_CURATION,
  REFRESH
} from "@/core/services/store/curations.module";

export default {
  props: {
    itemId: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    name: "",
    queries: [],
    valid: true,
    dialog: false
  }),
  methods: {
    refresh: function() {
      this.$store.dispatch(REFRESH);
      if (this.itemId > 0) {
        this.$store.dispatch(PULL_CURATION, this.itemId);
      }
    },
    addValue() {
      this.curation.queries.push({
        id: this.curation.queries.length + 1,
        query: ""
      });
    },
    removeValue: function(remIndex) {
      this.curation.queries.forEach(function(item, index, object) {
        if (item.id === remIndex) {
          object.splice(index, 1);
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let queries = [];
        this.$refs.f_queries.forEach(function(item) {
          queries.push(item.$refs.input.value);
        });

        if (this.itemId === 0) {
          this.$store
            .dispatch(POST_CURATION, {
              collection_id: this.collectionSelected.id,
              name: this.$refs.f_name.value,
              queries: queries,
              promoted: [],
              hidden: []
            })
            .then(data => {
              this.dialog = false;
              this.$store.dispatch(REFRESH);
              this.$router.replace({
                path: "curations/edit",
                query: { id: data.id }
              });
            });
        } else {
          let promoted = [];
          this.curation.promoted.forEach(function(item) {
            promoted.push(item.id);
          });
          let hidden = [];
          this.curation.hidden.forEach(function(item) {
            hidden.push(item.id);
          });

          this.$store
            .dispatch(PUT_CURATION, {
              id: this.itemId,
              data: {
                queries: queries,
                name: this.$refs.f_name.value
              }
            })
            .then(() => {
              this.dialog = false;
              this.$store.dispatch(PULL_CURATIONS);
            });
        }
      }
    }
  },
  validations: {
    form: {
      name: { required },
      type_id: { required }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.curations.errors,
      message: state => state.curations.message,
      hasMessage: state => state.curations.hasMessage,
      curation: state => state.curations.curation,
      collectionSelected: state => state.collections.collectionSelected
    })
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
  }
};
</script>
