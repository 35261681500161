var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([(_vm.itemId > 0)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticClass:"btn btn-primary font-weight-bolder",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.refresh($event)}}},on),[_vm._v(_vm._s(_vm.$t("curration.dialog.model.action.edit_record")))])]}}:{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticClass:"btn btn-primary font-weight-bolder",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.refresh($event)}}},on),[_vm._v(_vm._s(_vm.$t("curration.dialog.model.action.add_record")))])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("curration.dialog.model.title")))]),_c('small',[_vm._v(" "+_vm._s(_vm.$t("curration.dialog.model.subtitle"))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"alert fade alert-danger",class:{ show: _vm.errors.length },attrs:{"role":"alert"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i,staticClass:"alert-text"},[_vm._v(" "+_vm._s(error)+" ")])}),0)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"f_name",attrs:{"required":"","label":this.$t('curration.dialog.model.fields.name.label'),"rules":[
                  function (v) { return !!v || this$1.$t('curration.dialog.model.fields.name.rule'); }
                ]},model:{value:(_vm.curation.name),callback:function ($$v) {_vm.$set(_vm.curation, "name", $$v)},expression:"curation.name"}})],1)],1),_vm._l((_vm.curation.queries),function(query){
                var this$1 = this;
return _c('v-row',{key:query.id},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{ref:"f_queries",refInFor:true,attrs:{"required":"","label":_vm.$t('curration.dialog.model.fields.query.label'),"rules":[
                  function (v) { return !!v || this$1.$t('curration.dialog.model.fields.query.rule'); }
                ]},model:{value:(query.query),callback:function ($$v) {_vm.$set(query, "query", $$v)},expression:"query.query"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"error dark large","text":""},on:{"click":function($event){return _vm.removeValue(query.id)}}},[_vm._v(_vm._s(_vm.$t("common.remove"))+" ")])],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.addValue}},[_vm._v(_vm._s(_vm.$t("common.add_value")))])],1)],1)],2)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("common.close")))]),_c('v-spacer'),_c('v-btn',{ref:"submit",attrs:{"color":"primary dark large"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("common.save"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }