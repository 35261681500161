<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header flex-wrap py-3">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("curration.edit.title") }} ({{ this.curation.name }})
            <span class="d-block text-muted pt-2 font-size-sm"
              >{{ $t("curration.edit.subtitle") }} `{{
                queries.join("`, `")
              }}`.</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <a
            @click.prevent="deleteItem"
            href="#"
            class="btn btn-danger font-weight-bolder mr-2"
            >{{ $t("common.delete") }}</a
          >
          <v-flex>
            <curated_edit_dialog :item-id="this.$route.query.id" />
          </v-flex>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-xs-12">
            <!--begin::Card-->
            <div
              class="card card-custom gutter-b example example-compact bg-primary"
            >
              <div class="card-header">
                <h3 class="card-title text-white">
                  {{ $t("curration.edit.promoted.title") }}
                </h3>
              </div>
              <div class="card-body">
                <p class="text-white">
                  {{ $t("curration.edit.promoted.subtitle") }}
                </p>
                <draggable
                  :list="listPromoted"
                  class="list-group"
                  ghost-class="ghost"
                  @start="dragging = true"
                  @end="dragging = false"
                  @change="dragChange"
                  @dragstart="startDrag($event, item)"
                  handle=".draggable-handle"
                >
                  <div
                    v-for="item in listPromoted"
                    :key="item.title"
                    class="card card-custom gutter-b draggable drag-el"
                  >
                    <div class="card-header">
                      <div class="card-title">
                        <div
                          class="symbol symbol-40 flex-shrink-0 mr-4 bg-light"
                        >
                          <div
                            class="symbol-label"
                            :style="{
                              backgroundImage: 'url(' + item.image_link + ')'
                            }"
                          ></div>
                        </div>
                        <!--end::Symbol-->

                        <h3 class="card-label">
                          {{ item.name }} ({{ item.code }})
                        </h3>
                      </div>
                      <div class="card-toolbar">
                        <a
                          href="#"
                          @click.prevent="
                            expandToggle({ id: item._id, type: 'promoted' })
                          "
                          class="btn btn-icon btn-sm btn-hover-light-primary"
                        >
                          <i class="ki ki-info"></i>
                        </a>
                        <a
                          href="#"
                          @click.prevent="
                            togglePromoted({ id: item._id, add: false })
                          "
                          class="btn btn-icon btn-sm btn-hover-light-primary"
                        >
                          <i class="fas fa-star"></i>
                        </a>
                        <a
                          href="#"
                          @click.prevent=""
                          class="btn btn-icon btn-sm btn-hover-light-primary draggable-handle"
                        >
                          <i class="ki ki-menu "></i>
                        </a>
                      </div>
                    </div>
                    <div
                      class="card-body"
                      v-bind:id="`row__promoted__${item._id}`"
                      style="display:none"
                    >
                      <query_result :item="item" />
                    </div>
                  </div>
                </draggable>

                <div
                  class="empty-zone"
                  v-if="!listPromoted || listPromoted.length == 0"
                >
                  {{ $t("curration.edit.promoted.box.info") }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <!--begin::Card-->
            <div
              class="alert alert-custom alert-white alert-shadow fade show gutter-b"
              role="alert"
            >
              <form>
                <div class="form-group row">
                  <div class="col-9">
                    <input
                      class="form-control"
                      type="text"
                      v-model="searchString"
                    />
                  </div>
                  <div class="col-3">
                    <input
                      type="submit"
                      :value="$t('curration.edit.search.value')"
                      class="btn btn-light-primary font-weight-bolder mr-2"
                      @click.prevent="search"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 class="card-title">
                  {{ listOrganic.length }}
                  {{ $t("curration.edit.organic.title") }}&nbsp;{{
                    this.searchBuffer
                  }}
                </h3>
              </div>
              <div class="card-body">
                <p>{{ $t("curration.edit.organic.subtitle") }}</p>
                <div
                  v-for="item in listOrganic"
                  :key="item.title"
                  class="card card-custom gutter-b draggable drag-el"
                >
                  <div class="card-header">
                    <div class="card-title">
                      <div class="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                        <div
                          class="symbol-label"
                          :style="{
                            backgroundImage: 'url(' + item.image_link + ')'
                          }"
                        ></div>
                      </div>
                      <!--end::Symbol-->

                      <h3 class="card-label">
                        {{ item.name }} ({{ item.code }})
                      </h3>
                    </div>
                    <div class="card-toolbar">
                      <a
                        href="#"
                        @click.prevent="
                          expandToggle({ id: item._id, type: 'hidden' })
                        "
                        class="btn btn-icon btn-sm btn-hover-light-primary"
                      >
                        <i class="ki ki-info"></i>
                      </a>
                      <a
                        href="#"
                        @click.prevent="
                          togglePromoted({ id: item._id, add: true })
                        "
                        class="btn btn-icon btn-sm btn-hover-light-primary draggable-handle"
                      >
                        <i class="far fa-star"></i>
                      </a>
                      <a
                        href="#"
                        @click.prevent="
                          toggleHidden({ id: item._id, add: true })
                        "
                        class="btn btn-icon btn-sm btn-hover-light-primary draggable-handle"
                      >
                        <i class="far fa-eye-slash"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    class="card-body"
                    v-bind:id="`row__hidden__${item._id}`"
                    style="display:none"
                  >
                    <query_result :item="item" />
                  </div>
                </div>
                <div
                  class="empty-zone"
                  v-if="!listOrganic || listOrganic.length == 0"
                >
                  {{ $t("curration.edit.organic.box.info") }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <!--begin::Card-->
            <div
              class="card card-custom gutter-b example example-compact bg-danger "
            >
              <div class="card-header">
                <h3 class="card-title text-white">
                  {{ $t("curration.edit.hidden.title") }}
                </h3>
              </div>
              <div class="card-body">
                <p class="text-white">
                  {{ $t("curration.edit.hidden.subtitle") }}
                </p>
                <div
                  v-for="item in listHidden"
                  :key="item.title"
                  class="card card-custom gutter-b draggable drag-el"
                >
                  <div class="card-header">
                    <div class="card-title">
                      <div class="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                        <div
                          class="symbol-label"
                          :style="{
                            backgroundImage: 'url(' + item.image_link + ')'
                          }"
                        ></div>
                      </div>
                      <!--end::Symbol-->

                      <h3 class="card-label">
                        {{ item.name }} ({{ item.code }})
                      </h3>
                    </div>
                    <div class="card-toolbar">
                      <a
                        href="#"
                        @click.prevent="
                          expandToggle({ id: item._id, type: 'hidden' })
                        "
                        class="btn btn-icon btn-sm btn-hover-light-primary"
                      >
                        <i class="ki ki-info"></i>
                      </a>
                      <a
                        href="#"
                        @click.prevent="
                          toggleHidden({ id: item._id, add: false })
                        "
                        class="btn btn-icon btn-sm btn-hover-light-primary draggable-handle"
                      >
                        <i class="far fa-eye"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    class="card-body"
                    v-bind:id="`row__hidden__${item._id}`"
                    style="display:none"
                  >
                    <query_result :item="item" />
                  </div>
                </div>
                <div
                  class="empty-zone"
                  v-if="!listHidden || listHidden.length == 0"
                >
                  {{ $t("curration.edit.hidden.box.info") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.drop-zone {
  border: 3px dashed #eee;
  margin-bottom: 10px;
  padding: 30px;
}

.drag-el {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 20px;
}

.empty-zone {
  border: 3px dashed #eee;
  padding: 20px;
}
</style>
<script>
import curated_edit_dialog from "./curated_edit_dialog";
import { mapState } from "vuex";
import {
  CURATION_SEARCH,
  DELETE_CURATION,
  PULL_CURATION,
  PUT_CURATION
} from "../../../core/services/store/curations.module";
import draggable from "vuedraggable";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

import query_result from "@/view/pages/query-tester/result";
export default {
  components: {
    draggable,
    curated_edit_dialog,
    query_result
  },
  data() {
    return {
      searchBuffer: "",
      searchString: ""
    };
  },
  methods: {
    serialize: obj => {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
    getExcluded() {
      let excluded = [];
      this.listPromoted.forEach(function(item) {
        excluded.push(item._id);
      });

      this.listHidden.forEach(function(item) {
        excluded.push(item._id);
      });
      return excluded;
    },
    expandToggle(aggs) {
      var row = document.getElementById(`row__${aggs.type}__${aggs.id}`);

      if (row.style.display === "none") {
        row.style.display = "block";
      } else {
        row.style.display = "none";
      }
    },
    isExpanded(aggs) {
      var row = document.getElementById(`row__${aggs.type}__${aggs.id}`);

      if (row.style.display === "none") {
        return false;
      } else {
        return true;
      }
    },
    deleteItem: function() {
      Swal.fire({
        title: this.$t("common.dialog_delete.title"),
        text: this.$t("common.dialog_delete.content"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("common.dialog_delete.confirm")
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch(DELETE_CURATION, this.$route.query.id)
            .then(() => {
              this.$router.push({ name: "curations" });
            });
        }
      });
    },
    search() {
      this.searchBuffer = this.searchString;
      this.filterItems();
    },
    dragChange() {
      this.saveLists();
    },
    saveLists() {
      let promoted = [];
      this.listPromoted.forEach(function(item) {
        promoted.push(item._id);
      });
      let hidden = [];
      this.listHidden.forEach(function(item) {
        hidden.push(item._id);
      });
      let queries = [];
      this.listQueries.forEach(function(item) {
        queries.push(item.query);
      });

      this.$store
        .dispatch(PUT_CURATION, {
          id: this.$route.query.id,
          data: {
            queries: queries,
            promoted: promoted,
            hidden: hidden
          }
        })
        .then(() => {});
    },
    filterItems() {
      this.$store.dispatch(
        CURATION_SEARCH,
        this.serialize({
          curation_id: this.$route.query.id,
          q: this.searchBuffer,
          limit: 10,
          excluded: this.getExcluded()
        })
      );
    },
    togglePromoted(aggs) {
      if (aggs.add) {
        const item = this.listOrganic.find(item => item._id == aggs.id);
        this.listPromoted.push(item);

        const indexOfObject = this.listOrganic.findIndex(item => {
          return item._id === aggs.id;
        });

        this.listOrganic.splice(indexOfObject, 1);
      } else {
        const indexOfObject = this.listPromoted.findIndex(item => {
          return item._id === aggs.id;
        });

        this.listPromoted.splice(indexOfObject, 1);
      }
      this.filterItems();
      this.saveLists();
    },
    toggleHidden(aggs) {
      if (aggs.add) {
        const item = this.listOrganic.find(item => item._id == aggs.id);
        this.listHidden.push(item);

        const indexOfObject = this.listOrganic.findIndex(item => {
          return item._id === aggs.id;
        });

        this.listOrganic.splice(indexOfObject, 1);
      } else {
        const indexOfObject = this.listHidden.findIndex(item => {
          return item._id === aggs.id;
        });

        this.listHidden.splice(indexOfObject, 1);
      }
      this.filterItems();
      this.saveLists();
    }
  },
  computed: {
    ...mapState({
      listOrganic: state => state.curations.filteredItems,
      listPromoted: state => state.curations.curation.promoted,
      listHidden: state => state.curations.curation.hidden,
      listQueries: state => state.curations.curation.queries,
      queries: state => state.curations.curation.queriesList,
      curation: state => state.curations.curation
    })
  },
  mounted() {
    this.$store.dispatch(PULL_CURATION, this.$route.query.id);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("curration.title"), route: "/curations" },
      { title: this.$t("common.edit") }
    ]);
  }
};
</script>
